<template>
  <profile-form
    class="clinician-summary-credentialing-form ml-n4 pl-4 mr-n4"
    hide-clear
    disable-edit
  >
    <h6>Credentialing</h6>
    <v-text-field
      v-show="editMode"
      :value="clinician.licenses"
      type="text"
      outlined
      label="Routable Licenses"
      disabled
    />
    <data-row v-show="!editMode" label="Routable Licenses"
      ><state-chips :states="clinician.licenses"
    /></data-row>

    <v-text-field
      v-show="editMode"
      :value="nonRoutableLicenses"
      type="text"
      outlined
      label="Non-Routable Licenses"
      disabled
    />
    <data-row v-show="!editMode" label="Non-Routable Licenses"
      ><state-chips :states="nonRoutableLicenses" error="true"
    /></data-row>

    <v-text-field
      v-show="editMode"
      :value="clinician.medallion_last_sync | format"
      type="text"
      outlined
      label="Medallion Last Sync"
      disabled
    />
    <data-row
      v-show="!editMode"
      label="Medallion Last Sync"
      :value="clinician.medallion_last_sync | format"
    />
  </profile-form>
</template>

<script>
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import DataRow from '@/components/common/DataRow.vue'
import StateChips from '@/components/common/StateChips.vue'

export default {
  name: 'ClinicianSummaryCredentialing',
  components: {
    ProfileForm,
    DataRow,
    StateChips
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
    errorMessage() {
      return this.error?.message
    },
    nonRoutableLicenses() {
      let all = Object.keys(this.clinician.license_info)
      let routable = this.clinician.licenses
      let diff = all.filter((item) => routable.indexOf(item) < 0)
      return diff
    }
  }
}
</script>

<style lang="scss">
.clinician-summary-credentialing {
  &-form {
    width: calc(100% + size(32)) !important;
    margin-right: 36px;
    flex: 1;
  }
}
.static-field-view {
  & i {
    margin-right: 20px;
  }
}
</style>
